/* ============================================================================
   @MODULES -> MAP
   - Using Google Maps JS API
   - https://developers.google.com/maps/documentation/javascript/
   ========================================================================= */

var _vars = {};

// Get marker info from map element data attributes
var dataLabel = $('#js-location-map').attr('data-label'),
	dataLatitude = $('#js-location-map').attr('data-lat'),
	dataLongitude = $('#js-location-map').attr('data-lng'),
	dataAddress = $('#js-location-map').attr('data-addr');

// Prevent map dragging on touch devices.
var isDraggable = !('ontouchstart' in document.documentElement);

// Module variables
var _setVars = () => {
    _vars = {
		marker: {
		    title: dataLabel,
		    lat: dataLatitude,
		    lng: dataLongitude,
		    address: dataAddress
		},
		mapOptions: {
			center: new google.maps.LatLng(dataLatitude, dataLongitude),
	        zoom: 16,
			mapTypeControl: false,
			streetViewControl: false,
			draggable: isDraggable,
			scrollwheel: false
	    },
		customMapType: new google.maps.StyledMapType(
			[
			   {
				   "stylers": [
					   {
						   "saturation": -100
					   },
					   {
						   "gamma": 0.8
					   },
					   {
						   "lightness": 4
					   },
					   {
						   "visibility": "on"
					   }
				   ]
			   },
			   {
				   "featureType": "landscape.natural",
				   "stylers": [
					   {
						   "visibility": "on"
					   },
					   {
						   "color": "#5dff00"
					   },
					   {
						   "gamma": 4.97
					   },
					   {
						   "lightness": -5
					   },
					   {
						   "saturation": 100
					   }
				   ]
			   }
			], {
				name: 'Custom Style'
			}
		),
		customMapTypeId: 'custom_style'
    };
};


export default function init() {
    if ($('#js-location-map').length) initGoogleMap();
};


function initGoogleMap() {

	_setVars();

	/**
	 * Create map & infowindow Objects
	 */

    var infoWindow = new google.maps.InfoWindow();

    var map = new google.maps.Map(document.getElementById('js-location-map'), _vars.mapOptions);

	map.mapTypes.set(_vars.customMapTypeId, _vars.customMapType);
	map.setMapTypeId(_vars.customMapTypeId);


	/**
	 * Marker -
	 * This is only set as a variable and a custom marker/label hybrid is
	 * created further down. The usual method of adding markers is commented
	 * out below for reference.
	 */

	var markerLatLng =  new google.maps.LatLng(_vars.marker.lat, _vars.marker.lng);


	/**
	 * Marker WITH Label -
	 * A custom hybrid using an optional extension for Google Maps.
	 * http://google-maps-utility-library-v3.googlecode.com/svn/trunk/markerwithlabel/docs/reference.html
	 */

	var customMarkerLatLng = markerLatLng;

	var customMarker = new MarkerWithLabel({
		position: customMarkerLatLng,
		// icon: mapStyles.uavSymbolBlack,
		labelContent: '<div style="background-color: #000; border: border-radius: 0; color: #fff; font-size: 16px; font-weight: bold; letter-spacing: .1em; padding: 12.5px 20px; width: 200px; text-align: center; text-transform: uppercase;">' +
		_vars.marker.title +
		'</div>' +
		'<div style="border-color: #000 transparent transparent transparent; border-style: solid; border-width: 20px 12.5px 0 12.5px; height: 0; margin-left: 88px; width: 0;"></div>',
		labelAnchor: new google.maps.Point(85, 51),
		labelClass: "c-location-map__label",
		labelStyle: {
			opacity: 1
		},
		zIndex: 9000,
		map: map
	});


	/**
	 * Info Window ('infowindow') -
	 * Custom content in an infowindow that appears when clicking on the
	 * custom marker/label defined above.
	 */

	var contentString = '<div class="c-location-map__info-window">'+
		'<h3>' + _vars.marker.title + '</h3>' +
		'<span><a href="http://maps.google.com/?q=' + _vars.marker.address + '" target="_blank" style="color: #000; text-decoration: none;">' + _vars.marker.address + '</a></span>' +
		'</div>';

	var infowindow = new google.maps.InfoWindow({
		content: contentString,

		// Assign a maximum value for the width of the infowindow allows
		// greater control over the various content elements
		maxWidth: 350
	});

	google.maps.event.addListener(customMarker, "click", function (e) { infowindow.open(map, customMarker); });


	/**
	 * Custom infowindow Styling Overrides -
	 * The google.maps.event.addListener() event expects
	 * the creation of the infowindow HTML structure 'domready'
	 * and before the opening of the infowindow, defined styles are applied.
	 */

	google.maps.event.addListener(infowindow, 'domready', function() {

		// Reference to the DIV that wraps the bottom of infowindow, and it's parent.
		var iwOuter = $('.gm-style-iw');
		var iwParent = iwOuter.parent();

		/* Since this div is in a position prior to .gm-div style-iw.
		 * We use jQuery and create a iwBackground variable,
		 * and took advantage of the existing reference .gm-style-iw for the previous div with .prev().
		*/
		var iwBackground = iwOuter.prev();

		// Removes background shadow DIV
		iwBackground.children(':nth-child(2)').css({'display' : 'none'});

		// Removes white background DIV
		iwBackground.children(':nth-child(4)').css({'display' : 'none'});

		// Moves the infowindow 115px to the right.
		// iwOuter.parent().parent().css({left: '115px'}); // Not needed.

		// Moves the shadow of the arrow 76px to the left margin.
		iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'display: none;';});

		// Moves the arrow 76px to the left margin.
		iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'display: none;';});

		// Changes the desired tail shadow color.
		iwBackground.children(':nth-child(3)').find('div').children().css({'box-shadow': 'rgba(72, 181, 233, 0.6) 0px 1px 6px', 'z-index' : '1'});

		// Styling on the window's parent wrapper.
		// iwParent.attr('style', function(i,s){ return s + 'background: red;'}); // Not needed.

		// Reference to the div that groups the close button elements.
		var iwCloseBtn = iwOuter.next();

		// Apply the desired effect to the close button
		iwCloseBtn.css({opacity: '1', right: '10px', top: '4px'}).addClass('c-location-map__close');

		// If the content of infowindow not exceed the set maximum height, then the gradient is removed.
		if($('.iw-content').height() < 140){
			$('.iw-bottom-gradient').css({display: 'none'});
		}

		// The API automatically applies 0.7 opacity to the button after the mouseout event. This function reverses this event to the desired value.
		iwCloseBtn.mouseout(function(){
			$(this).css({opacity: '1'});
		});
	});
};
