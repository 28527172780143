/**
 * Full-screen Vimeo embed with a pre-roll & post-roll image, play and mute
 * controls.
 */


 // Utilities
 import { debounce } from './utils';


// Module options
const options = {
	fullscreen: false
}


// Cached elements
const $el = {
	window: $(window),
	body: $('body'),
	videoContainer: $('.js-video'),
	header: $('.c-site-header'),
	nav: $('.c-site-nav'),
	player: $('#c-hero-video__embed'),
	videoPlayButton: $('.c-hero-video__play'),
	videoMuteButton: $('.c-hero-video__mute'),
	playerOrigin: '*'
};


// Variables
let _vars = {
	viewportHeight: $el.window.height(),
	videoContainerHeight: 0,
	videoContainerWidth: $el.videoContainer.width(),
	headerHeight: 75, // We can't get the height of a position: relative element in the DOM so manually set the header height here.
	videoAudioOn: true,
	stoppedVideo: false
};


// Module events
export default function init() {
	loadVideo();
	bindEvents();
};


function bindEvents() {

	/**
	 * Volume Play Control
	 */

	$el.videoPlayButton.on('click', function() {
		// If the play button is clicked, ask the player to play the video.

		$el.player[0].contentWindow.postMessage('{"method":"play"}','*');
		loadVideo();
		$el.videoContainer.removeClass('is-ready');
		$el.videoContainer.addClass('is-playing');
	});


	/**
	 * Volume Mute Control
	 */

	$el.videoMuteButton.on('click', function() {
		// If the mute button is clicked, ask the  player to mute/umute the video.

		if(_vars.videoAudioOn == false){
			$el.videoContainer.removeClass('is-muted');
			$el.player[0].contentWindow.postMessage('{"method":"setVolume", "value":1}','*');
			_vars.videoAudioOn = true;
		} else {
			$el.videoContainer.addClass('is-muted');
			$el.player[0].contentWindow.postMessage('{"method":"setVolume", "value":0}','*');
			_vars.videoAudioOn = false;
		}
	});


	/**
	 * User Window resize
	 */

	$el.window.on('resize', function() {
		debounceResize();
	});
}


function loadVideo() {
	if($el.videoContainer.hasClass('is-fullscreen')) {
		options.fullscreen = true;

		$el.player = $('#c-video-full-screen__embed');
		$el.videoPlayButton = $('.c-video-full-screen__play');
		$el.videoMuteButton = $('.c-video-full-screen__mute');
	}

	setVideoToActive();
	setVideoPlayerSize();
}


function setVideoPlayerSize() {

	/* Adjust Video Player Size -
	 * Player is full-screen minus the height of the header, which sits at the top of the screen, still visible.
	 */

	checkViewportSize();

	// Check if video is flagged to be fullscreen.
	if((options.fullscreen === true) && (_vars.videoContainerWidth > 539)) {
		// If fullscreen, set the height of the video to fill window, minus size of header.

		$el.videoContainer.css({ 'height': (_vars.viewportHeight  - _vars.headerHeight) + "px" });
		$el.player.css({ 'height': (_vars.videoContainerHeight) + "px" });
		$el.player.css({ 'width': _vars.videoContainerWidth + "px" });
	} else {
		// If not fullscreen, set the height of the video in proportion to aspect ratio.

		// First reset any heights and widths that may have been set.
		$el.videoContainer.css({ 'height': '' });
		$el.player.css({ 'height': '' });
		$el.player.css({ 'width': '' });

		_vars.videoContainerHeight = $el.player.outerHeight();
		$el.videoContainer.css({ 'height': (_vars.videoContainerHeight) + "px" });
	}
}


function checkViewportSize() {

	/**
	 * Check the size of the user's viewport -
	 * Store the viewport size and related information in the assocaited
	 * variables; this info used by multiple functions.
	 */

	if ($el.header.hasClass('is-sticky')) {
		_vars.headerHeight = 40 + $el.nav.outerHeight();
	} else {
		_vars.headerHeight = $el.header.outerHeight() + $el.nav.outerHeight();
	}

	_vars.viewportHeight = $el.window.height();
	_vars.videoContainerHeight = _vars.viewportHeight  - _vars.headerHeight;
	_vars.videoContainerWidth = $el.videoContainer.width();

	if( $el.window.width() < 992 ) {
		_vars.headerHeight = $el.header.height();
		_vars.videoContainerHeight = _vars.viewportHeight  - _vars.headerHeight;
	}
}


function setVideoStatusToReady() {
	$el.videoContainer.addClass('is-ready');
}


function setVideoStatusToStopped() {
	$el.videoContainer.removeClass('is-active');
	$el.videoContainer.removeClass('is-playing');
	$el.videoContainer.addClass('is-inactive');
	_vars.stoppedVideo = true;
}


function unloadVideo() {

	// Check if the video has already been unloaded, if so, do nothing.
	if(_vars.stoppedVideo === false) {

		/* Unload Video -
		 * This unloads and then reloads the video; stopping it
		 * putting the playhead back to the start. */

		stopVideo();
	}
}


function stopVideo() {
	$el.player[0].contentWindow.postMessage('{"method":"pause"}','*');
	setVideoStatusToStopped();
	_vars.stoppedVideo = true;
}


function setVideoVolumeToMute() {
	// Talk to the Vimeo JS API to mute the player.
	$el.player[0].contentWindow.postMessage('{"method":"setVolume", "value":0}','*');
	_vars.videoAudioOn = false;
}

function setVideoToActive() {
	$el.videoContainer.addClass('is-active');
	_vars.stoppedVideo = false;
}


function onMessageReceived(event) {
	// Handle messages received from the player - from the Vimeo JS API: https://developer.vimeo.com/player/js-api

	// Handle messages from the vimeo player only
	if (!(/^https?:\/\/player.vimeo.com/).test(event.origin)) {
		return false;
	}

	if ($el.playerOrigin === '*') {
		$el.playerOrigin = event.origin;
	}

	var data = JSON.parse(event.data);

	switch (data.event) {
		case 'ready':
			onReady();
			break;

		case 'playProgress':
			onPlayProgress(data.data);
			break;

		case 'pause':
			onPause();
			break;

		case 'finish':
			onFinish();
			break;
	}
}


function post(action, value) {
	// Helper function for sending a message to the player - from the Vimeo JS API: https://developer.vimeo.com/player/js-api

	var data = {
	  method: action
	};

	if (value) {
		data.value = value;
	}

	var message = JSON.stringify(data);
	$el.player[0].contentWindow.postMessage(message, $el.playerOrigin);
}


function onReady() {
	// From the Vimeo JS API: https://developer.vimeo.com/player/js-api

	post('addEventListener', 'pause');
	post('addEventListener', 'finish');
	post('addEventListener', 'playProgress');

	setVideoStatusToReady();
}


function onPause() {
	// From the Vimeo JS API: https://developer.vimeo.com/player/js-api

	$el.videoContainer.removeClass('is-playing');
}


function onFinish() {
	// From the Vimeo JS API: https://developer.vimeo.com/player/js-api

	$el.videoContainer.removeClass('is-playing');
	$el.videoContainer.addClass('is-finished');
}


function onPlayProgress(data) {
	// From the Vimeo JS API: https://developer.vimeo.com/player/js-api

	if(data.seconds > 0) {
		// Video is past the start.
		$el.videoContainer.removeClass('is-finished');
		$el.videoContainer.removeClass('is-ready');
		$el.videoContainer.addClass('is-playing');
	}
}


/**
 * Talk to the Vimeo player API.
 * From the Vimeo JS API: https://developer.vimeo.com/player/js-api
 */

if (window.addEventListener) {
	window.addEventListener('message', onMessageReceived, false);
}
else {
	window.attachEvent('onmessage', onMessageReceived, false);
}


const debounceResize = debounce(function() {
	// On User Window Resize, adjust the payer dimensions.
	setVideoPlayerSize();
}, 250);
