// Utilities
import { debounce } from './utils';
import { resizeEnd } from './utils';


// Module variables
let _vars = {
	scrollOffsetApplied: false,
	navIsStuck: false,
    scrollPos: 0,
    previousScrollPos: 0,
	drawerHeight: 0,
	siteNavPosition: -1
};

// Cached elements
const $el = {
	window: $(window),
	doc: $(document),
	body: $('body'),
	navContainer: $('.js-site-nav'),
	navTrigger: $('.js-site-nav-trigger'),
	navItemsTopLevel: $('.js-site-nav > ul > li')
};


// Module events
export default function init() {

	/**
	 * Check if menu trigger and the menu itself exist in the DOM.
	 * If they do, then go ahead. If they don't exist, do nothing.
	 */

	if ($el.navTrigger.length && $el.navContainer.length) {
		bindEvents();
	}
};


function bindEvents() {

	/**
	 * Menu Trigger Click
	 * When clicked, flag menu as active.
	 */

	$el.navTrigger.on('click', (e) => {
		e.preventDefault();

		if(!$el.navContainer.hasClass('is-active')) {
			_makeMenuActive();
		} else {
			_makeMenuInactive();
		}
	});


	/**
	 * Menu Click Events
	 */

	$('.has-children > a').on('click', function(e) {
		var $target = $(this);

		e.preventDefault();

		// Check if the clicked element has it's sub-menu already visible.
		if($target.parents('.has-children').hasClass('is-active')) {

			// If yes, then just close the menu.
			_closeCurrentlyOpenMenus();
		} else {

			// Otherwise, close any open menu ...
			_closeCurrentlyOpenMenus();

			// ... and open this link's sub-menu.
			_openChildMenu($target);
		}
	});


	/**
	 * User Scroll
	 */

	$el.window.on('scroll', function() {
		debounceScroll();
	});


	/**
	 * User Window resize
	 */

	$el.window.on('resize', function() {
		debounceResize();
	});
};

const debounceScroll = debounce(function() {
	_checkIfNavShouldBeStuck();
}, 10);


const debounceResize = debounce(function() {
	_makeMenuInactive();

	_setNavPosition();
	_checkIfNavShouldBeStuck();
}, 200);


function _checkIfNavShouldBeStuck() {
	_vars.scrollPos = window.pageYOffset;

	// If the position of the site navigation hasn't yet been defined, do it now.
	if(_vars.siteNavPosition === -1) {
		_setNavPosition();
	}

	if((_vars.scrollPos >= _vars.siteNavPosition) && (_vars.navIsStuck === false)) {

		// Flag the menu with a class to say it should be stuck now.
		$el.navContainer.addClass('is-stuck');
		$el.body.addClass('has-stuck-header');
		_vars.navIsStuck = true;

	} else if((_vars.scrollPos < _vars.siteNavPosition) && (_vars.navIsStuck === true)) {

		// Un-flag the menu with the class to say it should be stuck.
		$el.navContainer.removeClass('is-stuck');
		$el.body.removeClass('has-stuck-header');
		_vars.navIsStuck = false;

	}
}


function _setNavPosition() {
	_vars.siteNavPosition = $el.navContainer.offset().top - 40; // Minus 40px as that's how the height of the header once it moves into place.
}


function _makeMenuActive() {
	_vars.previousScrollPos = window.pageYOffset;

	$el.body.addClass('has-active-menu');
	$el.navContainer.addClass('is-active');

	if (!_vars.scrollOffsetApplied) {
        _vars.scrollOffsetApplied = true;

        // Scroll to top when menu open
        window.scrollTo(0, 0);
    }
};


function _makeMenuInactive() {
	$el.body.removeClass('has-active-menu');
	$el.navContainer.removeClass('is-active');

	if (_vars.scrollOffsetApplied) {
        // Return window to original scroll position
		window.scrollTo(0, _vars.previousScrollPos);
		_vars.scrollOffsetApplied = false;
    }
};


function _closeCurrentlyOpenMenus() {
	$('.js-site-nav .is-active').removeClass('is-active');
};
