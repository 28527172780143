/**
 * Initialises Flickity slider (included as a node module on project).
 */

// Dependencies
const Flickity = require('flickity');

// Module options
const options = {
	cellSelector: '.c-hero-slider__item',
	wrapAround: true,
	prevNextButtons: false,
	cellAlign: 'center',
	autoPlay: 3000,
	draggable: false
};

// Cached elements
const $el = {
	slider: $('.js-hero-slider')
};

// Module events
export default function init() {

	/**
	 * Check if a slider element exists on the page in the DOM.
	 * If there is one, then go ahead. If none exist, our work here is done.
	 */

	if ($el.slider.length) {
		$el.slider.each(function() {
			$(this).data({
		        Flickity: new Flickity(this, options)
		    });
	    });
	}
};
