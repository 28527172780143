/**
 * Goes through items with 'js-match-height' class make matches each element's
 * height so they align in a grid/table-like structure.
 */

// Dependencies
require('jquery-match-height');

// Utilities
import { debounce } from './utils';

// Module options
const options = {
	byRow: true,
    property: 'height',
    target: null,
    remove: false
};

// Cached elements
const $el = {
	item: $('.js-match-height')
};

// Module events
export default function init() {
    bindEvents();
};

// User interactions go here
function bindEvents() {
	$el.item.matchHeight(options);
};
