/**
 * Forms
 */

// Dependencies
// require('chosen');
require('parsleyjs');

// Cached elements
const $el = {
	form: $('.js-validate'),
	dropdown: $('.js-dropdown')
};

const chosenOptions = {
	disable_search: true,
	inherit_select_classes: true,
	width: '100%'
};

const parsleyOptions = {
	classHandler: (ParsleyField) => {
		return ParsleyField.$element.parent();
	},
	excluded: 'input[type=submit], input[type=reset], input[type=checkbox]'
};

export default function init() {

	// Initialise Select2
	// $el.dropdown.chosen(chosenOptions);

	// Parsley Validation
	if ($el.form.length) $el.form.parsley(parsleyOptions);

};
