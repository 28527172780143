/**
 * Main Application
 * Controls the application, all modules are initialised here
 */

// Inline Dependencies
require('./libs/jquery.easing-1.3.2.min.js');
require('./plugins/pointer-events-polyfill');

// Utilities
import attachFastClick from 'fastclick';
import { iOSDetect } from './modules/utils';

// Require modules here:
import navigation from './modules/navigation';
import forms from './modules/forms';
import heroSlider from './modules/heroSlider';
import homeHeader from './modules/homeHeader';
import smoothScroll from './modules/smoothScroll';
import heroVideo from './modules/heroVideo';
import matchHeight from './modules/matchHeight';
import accordion from './modules/accordion';
import map from './modules/map';

// Launch our App
(function (window, document) {

	// Initialise fastclick
	attachFastClick(document.body);

	// Body is not loading anymore
	$('body').removeClass('is-loading');

	// iOS detection classes
	iOSDetect();

	// Modules
	navigation();
	forms();
	heroSlider();
	homeHeader();
	smoothScroll();
	heroVideo();
	matchHeight();
	accordion();
	map();

})(window, document);
