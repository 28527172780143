/**
 * Accordion - expandable/contractable sections that reveal additional
 * content. Y'know, an accorion.
 */

 // Utilities
 import { debounce } from './utils';


// Module options
const _options = {
    speed: 300,
	multiOpen: false
};


// Cached elements
const $el = {
	window: $(window),
	accordionContainer: $('.js-accordion'),
	accordionItems: $('.js-accordion >  li'),
	accordionExpandLink: $('.js-accordion-trigger'),
	accordionContent: $('.o-list-panel__extra'),
	siteHeader: $('.js-site-header'),
	siteNavigation: $('.js-site-nav')
};


// Module Variables
let _vars = {
	viewportWidth: $el.window.width(),
	isMobileOnly: false
}


// Module events
export default function init() {

	/**
	 * Check if accordion container elements exists in the DOM.
	 * If it does, then go ahead. If they don't exist, do nothing.
	 */

	if ($el.accordionContainer.length) {
		checkifMobileOnly();
		applyAccordionIfMobileOnly();
	}
};


function checkifMobileOnly() {
	if($el.accordionContainer.hasClass('is-mobile-only')) {
		// console.log('This accordion has been flagged mobile-only.');
		_vars.isMobileOnly = true;
	};
};

function applyAccordionIfMobileOnly() {
	if((_vars.viewportWidth >= 768) && (_vars.isMobileOnly === true)) {

		// This is a mobile-only accordion, and currently NOT viewing on mobile.
		closeAllAccordionItems();
		disableAccordion();
	} else {

		// Is not mobile-only, or is mobile-only and being loaded on mobile - so load accordion.
		bindEvents();
	};


	/**
	 * User Window resize
	 */

	$el.window.on('resize', function() {
		debounceResize();
	});
};


function bindEvents() {

	// Loop through accordion items
	$.each($el.accordionItems, function(i, obj) {
		let $item = $(obj),
			$content = $item.find($el.accordionContent),
			$trigger = $item.find($el.accordionExpandLink);

		// Check if this item has content, if it does, flag it with a class.
		if(($content).length !== 0){
			$item.addClass('has-content');

			if($trigger.hasClass('c-btn')) {
				// console.log('Has a button as a trigger.');
				$item.addClass('has-trigger-btn');
			};

			$trigger.on('click', (e) => {
				e.preventDefault();

				// Check if clicking on the currently active tile.
				if($item.hasClass('is-active')) {

					// Active tile was clicked, close it.
					closeAccordionItem($content, $item);
				} else {

					// Check if the option to have multiple accordion items open is set to true.
					if(_options.multiOpen === false) {

						// Option is set to false, close all the other accordions.
						closeAllAccordionItems();
					}

					// Open up the clicked accordion item's content.
					openAccordionItem($content, $item);
				}
			});
		}
	});

	if(window.location.hash) {
		let $hash = window.location.hash.substring(1), //Puts hash in variable, and removes the # character
			$item = $('#'+$hash);

		if($item.length > 0) {
			let $content = $item.find($el.accordionContent);

			if($content.length > 0) {

				// Open the accordion item whose ID matches the hash in the URL.
				openAccordionItem($content, $item);

				// Animate scroll to that element.
				let offset = $el.siteHeader.outerHeight();

				if(_vars.viewportWidth > 768) {
					offset = $el.siteHeader.outerHeight() + $el.siteNavigation.outerHeight();
				};

				$('html,body').animate({
					scrollTop: ($item.offset().top - offset)
				}, 500);
			}
		}
	}
};

const debounceResize = debounce(function() {
	// On User Window Resize, check if the accordion still needs to function.
	_vars.viewportWidth = $el.window.width();

	checkifMobileOnly();
	disableAccordion();
	applyAccordionIfMobileOnly();
}, 250);


function openAccordionItem($content, $item) {
	$content.slideDown({ duration: _options.speed });
	$item.addClass('is-active');
};


function closeAccordionItem($content, $item) {
	$content.slideUp({ duration: _options.speed });
	$item.removeClass('is-active');
};


function closeAllAccordionItems() {
	$('.is-active .o-list-panel__extra').slideUp({ duration: _options.speed });
	$('.is-active').removeClass('is-active');
};

function disableAccordion($item, $content, $trigger) {
	// Loop through accordion items
	$.each($el.accordionItems, function(i, obj) {
		// Check if this item has content, if it does, remove accordion functionality.
		let $item = $(obj),
			$content = $item.find($el.accordionContent),
			$trigger = $item.find($el.accordionExpandLink);

			if(($content).length !== 0){
				$item.removeClass('has-content');
				$trigger.off('click');
			};
	});
};
