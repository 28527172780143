/**
 * Intro block explaining what this module does. Go into as much detail as needed,
 * the dev reading this should be able to understand what this module does
 * without having to read any code. List any plugins used.
 */

 // Utilities
 import { debounce } from './utils';

// Module options
const options = {
	offset: 75
};

// Cached elements
const $el = {
	window: $(window),
	siteHeader: $('.js-site-header'),
	siteNavigation: $('.js-site-nav')
};

// Variables
let _vars = {
	viewportHeight: $el.window.height(),
	viewportWidth: $el.window.width()
}

// Module events
export default function init() {
	setVars();
    bindEvents();
};

// User interactions go here
function bindEvents() {

	// Listen for when a link is clicked that starts with a hash, but isnm't just a hash.
	$('a[href*=#]:not([href=#])').click(function() {

		// Check ths link is not external and is on the current page.
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			let target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

			if (target.length) {
				// Animate scroll to that element.
				$('html,body').animate({
					scrollTop: (target.offset().top - options.offset)
				}, 500);
				return false;
			}
		}
	});


	/**
	 * User Window resize
	 */

	$el.window.on('resize', function() {
		debounceResize();
	});
};

const debounceResize = debounce(function() {
	setVars();
}, 250);

function setVars() {
	_vars.viewportHeight = $el.window.height();
	_vars.viewportWidth = $el.window.width();

	if (!$el.siteHeader.hasClass('is-sticky')) {
		options.offset = $el.siteHeader.outerHeight() + $el.siteNavigation.outerHeight();
	} else if (_vars.viewportWidth < 992) {
		options.offset = 40;
	} else {
		options.offset = 75;
	}
}
