// Utilities
import { debounce } from './utils';


// Module variables
let _vars = {
	headerIsStuck: false,
    scrollPos: 0,
	triggerOffset: -1
};

// Cached elements
const $el = {
	window: $(window),
	siteHeader: $('.c-site-header.is-sticky'),
	firstSection: $('.c-hero-slider')
};


// Module events
export default function init() {

	/**
	 * Check if header element exists in the DOM.
	 * If it does, then go ahead. If it doesn't exist, do nothing.
	 */

	if ($el.siteHeader.length) {
		bindEvents();
	}
};


function bindEvents() {

	/**
	 * User Scroll
	 */

	$el.window.on('scroll', function() {
		debounceScroll();
	});


	/**
	 * User Window resize
	 */

	$el.window.on('resize', function() {
		debounceResize();
	});
};

const debounceScroll = debounce(function() {
	_checkIfHeaderShouldBeStuck();
}, 10);


const debounceResize = debounce(function() {
	_setTriggerOffset();
	_checkIfHeaderShouldBeStuck();
}, 200);


function _checkIfHeaderShouldBeStuck() {
	_vars.scrollPos = window.pageYOffset;

	// If the trigger offset hasn't yet been defined, do it now.
	if(_vars.triggerOffset === -1) {
		_setTriggerOffset();
	}

	if((_vars.scrollPos >= _vars.triggerOffset) && (_vars.headerIsStuck === false)) {

		// Flag the menu with a class to say it should be stuck now.
		$el.siteHeader.addClass('is-stuck');
		_vars.headerIsStuck = true;

	} else if((_vars.scrollPos < _vars.triggerOffset) && (_vars.headerIsStuck === true)) {

		// Un-flag the menu with the class to say it should be stuck.
		$el.siteHeader.removeClass('is-stuck');
		_vars.headerIsStuck = false;

	}
}

function _setTriggerOffset() {
	// The offset is the height of the first section of the page.
	// _vars.triggerOffset = $el.firstSection.outerHeight() - $el.siteHeader.outerHeight();
	_vars.triggerOffset = $el.firstSection.outerHeight() - 40; // Minus 40px as that's how the height of the header once it moves into place.
}
